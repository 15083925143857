@import '../splash.scss';

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.modal-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height:auto;
  background-color:rgb(234, 235, 236);
  border:3px solid rgb(248, 249, 250);
  border-radius: 4px;
}

.errors-li{
  list-style: none;
  display: inline-block;
}

.auth-form-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}

.signup-form-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 550px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}

.auth-form{
  width: 100%;
}

.auth-form .login-email, .login-password, .login-handle{
  width:100%;
  text-align: center;
  height: 40px;
  border: none;
  box-sizing: border-box;
  background-color: white;
  // border: 1px solid rgb(173, 255, 153);
  border: 1px solid rgb(255, 202, 27);
  font-size: 15px;
  margin-bottom: 5px;
}



.login-email:focus, .login-password:focus{
  // background-color: rgb(173, 255, 153);
  outline: none;
}

.auth-form .login-email::placeholder, .login-password::placeholder, .login-handle::placeholder{
  color: rgb(163, 163, 163);
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
}

.signup-email, .signup-password, .signup-handle{
  width:80%;
  text-align: center;
  height: 40px;
  border: 1px solid rgb(179, 179, 179);
  box-sizing: border-box;
  font-size: 15px;
  margin-bottom: 5px;
}

.signup-email-label, .signup-password-label, .signup-handle-label{
  width:100%;
  text-align: center;
  box-sizing: border-box;
  font-size: 30px;
}

.signup-password2{
  width:80%;
  text-align: center;
  height: 40px;
  border: 1px solid rgb(179, 179, 179);
  box-sizing: border-box;
  font-size: 15px;
  margin-bottom: 20px;

}

.login-email-label{
  position: relative;
  width: 80%;
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  color: rgb(68, 68, 68);
}

.login-handle-label {
  position: relative;
  width: 80%;
  font-weight: 300;
  font-size: 14px;
  color: rgb(68, 68, 68);
}

.login-password-label {
  position: relative;
  width: 80%;
  font-weight: 300;
  font-size: 14px;
  color: rgb(68, 68, 68);
}

.error{
  color: rgb(255, 85, 85);
  font-size: 12px;
  position: absolute;
  top: 3px;
  right: 0;
}

.login-header {
  font-size: 30px;
}

.signup-header {
  font-size: 30px;
  margin-bottom: 20px;
}


.submit-form-btn{
  text-align: center;
  border: none;
  border-radius: 4px;
  height: 40px;
  width: 80%;
  font-size: 15px;
  transition: all .5s;
  background-color: $red;
  cursor: pointer;
  color: white;
}

.submit-form-btn:hover{
  background-color: rgb(204, 36, 31);
}

.demo-form-btn {
  text-align: center;
  border: none;
  border-radius: 4px;
  height: 40px;
  width: 80%;
  font-size: 15px;
  transition: all .5s;
  background-color: rgb(226, 110, 89) ;
  cursor: pointer;
  color: white;
  margin-top: 5px;
}

.demo-form-btn:hover {
  background-color: rgb(192, 31, 31);
}

.bottom-text{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  align-items: center;
}

.no-account {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.signup-bottom {
  border: none;
  background-color: transparent;
  color: $red;
  font-size: 12px;
  cursor: pointer;
}

.form-div{
  display:flex;
  flex-direction:column;
  align-items: center;
  position: relative;
}

.navbar{
  display:flex;
  align-items:center;
  justify-content: space-between;
  background-color: rgb(252, 101, 81);
  height: 70px;
  width:100%;
}

.login-btn{
  // margin-left: 700px;
  padding: 12px;
  border-radius: 4px;
  font-family: $main-font;
  color: white;
  
  background: rgb(252, 101, 81);
  height: 50px;
  width: 100px;
  transition: all .5s;
  border: none;
  cursor: pointer;
}

.login-btn:hover{
  // margin-left: 700px;
  
  background-color: rgb(91, 213, 218);
}

.signup-btn{
  margin-left: 20px;
  padding:12px;
  margin-right: 50px;
  border-radius: 4px;
  font-family: $main-font;
  color: white;
  height: 50px;
  // background: rgb(13, 177, 109);
  background: rgb(252, 101, 81);
  
  border: none;
  transition: all .5s;
  cursor: pointer;
}

.button-row{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-btn:hover{
  // margin-left: 700px;
  // background: rgb(7, 122, 74);
  background-color: rgb(91, 213, 218);
}

.logo{
  margin-left: 100px;
  font-size: 24px;
  font-family: $main-font;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

a:visited{
  color: white;
  cursor: pointer;
}

.logout-btn {
  margin-left: 20px;
  // padding:12px;
  margin-right: 50px;
  // border-radius: 4px;
  font-family: $main-font;
  color: white;
  height: 100%;
  // background: rgb(13, 177, 109);
  background: rgb(252, 101, 81);
  
  border: none;
  transition: all .5s;
  cursor: pointer;
}

.nav-container {
  height: inherit;
  display: flex;
  align-items: center;
}


.logout-btn:hover{
  background-color: rgb(21, 197, 197);
}

.other-btn {
  margin-left: 20px;
  padding: 10px;
  border-radius: 3px;
  font-family: $main-font;
  color: white;
  font-size: 18px;
  // background: rgb(13, 177, 109);
  background: rgb(252, 101, 81);
  
  border: none;
  transition: all .5s;
  cursor: pointer;
}

.other-btn:first-child{
  margin-left: 10px;
}

.other-btn:hover{
  background-color: rgb(21, 197, 197);
}

.welcome {
  color: white;
  font-size: 13px;
}
