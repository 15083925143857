.search-bar-container{
    position: absolute;
    left: 20%;
    top: 3%;
}

.search-bar-text{
    color: yellowgreen;
    margin-right: 5px;
}

.search-bar-input{
    width: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-right: 5px;
    height: 20px;
    padding-left: 10px;
}

.search-bar-submit{
    border-radius: 50%;
    background-color: transparent;
    color: white;
    text-align: center;   
    border: none;
    transition: all .3s;
    cursor: pointer;
   
}
  .search-bar-submit:hover{
        color: rgb(91, 213, 218);
    }