$main-font: 'Ubuntu', sans-serif;
$second-font: 'Raleway', sans-serif;
$red: rgb(252, 101, 81);


header {
    height: max-content;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: rgb(253, 230, 127);
    font-family: $main-font;
}

.hidden {
    display: none;
}

.tagline {
    font-family: $main-font;
    font-weight: 300;
    color:rgb(255, 241, 241);
    font-size: 50px;
    position: absolute;
    top: 300px;
    left: 850px;
    width: 800px;
    overflow-wrap: break-word;
}

.circle {
    position: absolute;
    left: -200px;
    top: 200px;
    height: 1000px;
    width: 1000px;
    border-radius: 50%;
    background-image: url('../images/circle2.png');
    background-size: 1000px;
    animation-name: rotate;
    animation-duration: 80s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    overflow: hidden;
}

@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}

.splash-content {
    height: 750px;
    position: relative;
    overflow: hidden;
}

.main-content {
    min-height: 750px;
    background: linear-gradient(#eccb0d, #fdaa2d);
}

footer {
    height: 30px;
    background-color: rgb(253, 230, 127);
    width: 100%;

}

.footerIcons {    
    display:flex;
    justify-content: space-evenly;
    // padding-top: 20px;
    align-items: center;
    background-color: rgb(253, 230, 127);
    height: 110px;
}

.topFooterIcons {
    display:flex;
    justify-content: center;
}


.gitHub-Icon {
    font-size: 30px;
    a {
    display: block;
    // background-image: url('../images/github-icon.svg');
    // width:50px;
    // height:50px;
    color: gray;
    transition: all .3s;
    }

    a:hover {
        color: rgb(91, 213, 218);
    }
}

.linkedIn-Icon {
    font-size: 30px;

    a {
    display: block;
    // background-image: url('../images/linkedin-icon.svg');
    // width:50px;
    // height:50px;
    margin: 0 5px;
    transition: all .3s;
    color: gray;
    }

    a:hover {
        color: rgb(91, 213, 218);
    }
}

.angellist-Icon {
    font-size: 30px;

    a {
    display: block;
    // background-image: url('../images/angellist-icon.svg');
    // width:50px;
    // height:50px;
    transition: all .3s;
    color: gray;
    }

    a:hover {
        color: rgb(91, 213, 218);
    }
}

.bio-name {
    display:flex;
    justify-content: center;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
}

.bios {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
}

.botFooter {
    // margin-bottom: 10px;
}