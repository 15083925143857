.friend-wrapper{
    background-color: rgb(235, 232, 232);
    width: 200px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 213);
    box-shadow: 1px 1px 3px 1px #aaaaaa;
    height: max-content;
    padding-bottom: 10px;

}

.friend-live-item{
    background-image: linear-gradient(to right, rgb(255, 255, 197) , lightblue);
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    height: max-content;
    margin-top: 2px;
}

.friend-buttons-live{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.friend-page-button{
    margin:5px;
}

.friend-name-live {
    padding-top: 13px;
    font-size: 15px;
    color: rgb(0, 40, 100);
    font-family: $main-font;
    font-weight: 700;
    box-sizing: border-box;
    margin-left: 10px;
    border: none;
    background-color: transparent;
    transition: all .3s;
    cursor: pointer;
}

.friend-name-live:hover{
    color:rgb(161, 161, 161);
}