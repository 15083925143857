@import '../splash.scss';

.post-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
}

.post-form {
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height:auto;
  background-color:rgb(234, 235, 236);
  border:3px solid rgb(248, 249, 250);
  border-radius: 4px;
}

.post-heading {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.postAvatarImg {
  margin-left: 5px;
}

.post-image{
    max-width: 100%;
    min-width: 100%;
}

.rest-name{
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.rest-address{
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.post-body {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
}

.delete-post {
  border: none;
  background-color: transparent;
  color: rgba(16, 196, 209, 0.904);
  height: 40px;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;

  i {
    color: rgba(16, 196, 209, 0.904);
    transition: all .3s;
  }

  i:hover {
    color: $red;
  }
}

.delete-post:hover{
  color: $red;
}

a.delete-post{
  color: rgba(16, 196, 209, 0.904);
  display: block;
  line-height: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
}

.post-buttons{
  position: absolute;
  right: 0px;
  top: 15px;
  display: flex;
}

.post-form {
  height: 500px;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.rest-label, .post-address-label, .post-body-label, .post-label {
  margin: 5px 0 5px 0;
  width: 90%;
  font-size: 13px;
  color:rgba(82, 82, 82, 0.966);
  position: relative;
}

.post-image {
  margin-bottom: 5px;
}

.rest-label input, .post-address-label input, .post-body-label input {
  height: 40px;
  width: 350px;
  border: 1px solid rgba(255, 194, 64, 0.904);
  border-radius: 4px;
}

textarea::placeholder, .rest-label input::placeholder, .post-address-label input::placeholder, .post-body-label input::placeholder {
  color:rgb(179, 179, 179);
  font-weight: 300;
  font-family: $main-font;
}
textarea {
  height: 70px;
  width: 350px;
  border: 1px solid rgba(255, 194, 64, 0.904);
  border-radius: 4px;
  padding: 5px;
}

.post-header {
  font-size: 30px;
  margin-bottom: 20px;
}

.post-errors{
  font-size: 12px;
  color: rgb(243, 45, 45);
  position: absolute;
}

.edit-form-div {
  width: 800px;
  background-color: white;
  margin: 0 auto;
  min-height: 600px;
  padding: 30px;
}

.edit-form {
  width: 600px;
  height:max-content;
  margin: 0 auto;
}

.edit-image {
  margin: 20px;

  img {
    max-width: 500px;
  }
}

.edit-rest, .edit-address, .edit-body{
  width:100%;
  height: 40px;
  border: none;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid rgb(255, 202, 27);
  font-size: 15px;
  margin-bottom: 5px;
}

.file-errors {
  color: rgb(175, 0, 0);
  position: absolute;
  bottom: 90px;
  left: 20px;
  font-size: 13px;
}

.heart {
  border: none;
  background-color: transparent;
  color: rgb(87, 87, 87);
  font-size: 15px;
  cursor: pointer;
}

.empty-heart {
  border: none;
  background-color: transparent;
  font-size: 17px;
  color: rgb(255, 100, 100);
  cursor: pointer;
}

.like-num {
  font-size: 12px;
}

.like-num-btn {
  border: none;
  background-color: transparent;
  padding: none;
  cursor: pointer;
}

.like-num-btn:hover {
  color: $red;
}

.like-row{
  margin: 10px 0;
}

.liker-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(187, 187, 187, 0.7);
    z-index: 10;
}

.likers {
  background-color: white;
  height: max-content;
  width: max-content;
  min-height: 200px;
  min-width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 3px;
  color: $red;
  padding: 10px;
  transform: translate(-50%, -50%);
}

.liker {
  margin-top: 10px;
  color: rgb(99, 99, 99);
}

.no-post {
  padding-left: 20px;
}