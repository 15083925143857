@import './../splash.scss';

.friend-list-item{
    height: 50%;
    // border: 1px solid black;
    // display: flex;
    text-align: center;
    margin-bottom: 2px;
}

.friends-bar{
    width: 200px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 213);
    box-shadow: 1px 1px 3px 1px #aaaaaa;
    height: max-content;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    // justify-content: space-evenly;
}

.friends-bar-title {
    height: 43px;
    font-size: 25px;
    font-weight: 700;
    padding: 20px 0 0 10px;
}

.friend-list-item{
    background-image: linear-gradient(to right, rgb(255, 255, 197) , lightblue);
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center;
    height: max-content;

}

.friend-info{
    display: flex;
    position: relative;

    img {
        width: 40px;
        height: 40px;
    }
}

.friend-profile-photo{
    // display: inline-block;
}

.friend-name{
    // padding-top: 5%;
    font-size: 15px;
    color: rgb(0, 40, 100);
    font-family: $main-font;
    font-weight: 700;
    box-sizing: border-box;
    margin-left: 10px;
    border: none;
    background-color: transparent;
    transition: all .3s;
    cursor: pointer;
}

.friend-name:hover{
    color:rgb(161, 161, 161);
}


.friend-page-button{
    color: white;
    background-color: rgba(16, 196, 209, 0.904);
    text-decoration: none;
    font-family: $main-font;
    height: 40px;
    border: none;
    border-radius: 5px;
    width: 100px;
    margin-top: 10px;
    box-sizing: border-box;
    transition: all .3s;
    cursor: pointer;
}

.friend-page-messenger {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    color: rgba(16, 196, 209, 0.904);
    position: absolute;
    right: 5px;
    top: 5px;
    text-decoration: none;
    font-family: $main-font;
    height: 30px;
    border-radius: 50%;
    width: 30px;
    box-sizing: border-box;
    transition: all .3s;
    cursor: pointer;
    
}

.friend-page-messenger:hover, .friend-page-button:hover {
    // background-color: rgb(255, 255, 209);
    color: rgb(22, 224, 157);
}

.friend-buttons{
    display: flex;
    justify-content: center;
}