.profile-page-container{
    // height: 100%;
    // position: absolute;
    width: 100%;
}

.profile-info-container{
    width: 1200px;
    height: 100%;
    padding-top: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    // border: 1px solid black;
}

.profile-picture{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 250px;
    height: max-content;
    box-shadow: 1px 1px 3px 1px rgb(170, 170, 170);
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    position: sticky;
    top: 0;

    img {
        width: 100px;
    }
}

.profile-name{
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin: 20px;
}

.profile-posts{
    width: 40%;
    border: 1px solid red;
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-evenly;
}

.about-me{
    margin-bottom: 20px;
    font-family: $second-font;
}

.profile-remove-friend, .profile-add-friend{
    background-color: rgb(247, 174, 40);
    border: none;
    height: 40px;
    width: 150px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    transition: all .3s;
}

.profile-remove-friend:hover, .profile-add-friend:hover{
    background-color: rgb(214, 139, 0);
    cursor: pointer;
}

.not-own-friend {
  border: none;
  background-color:rgba(16, 196, 209, 0.904);
  color: white;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
}

.not-own-friend:hover{
  background-color: rgb(255, 255, 209);
  color: $red;
}

.profilebackground {
    position: fixed,
}

// .profile-picture > div {
//     padding-top: 50px;
// }

.avatarButton {
    border: none;
    background-color:rgba(16, 196, 209, 0.904);
    color: white;
    border-radius: 4px;
    height: 30px;
    margin-top: 5px;
    transition: all .3s;
    cursor: pointer;
}

.avatarButton:hover{
    background-color: rgb(255, 255, 209);
    color: $red;
}

.avatarBankButtons {
    display: flex;
}

.avatarBankBox {
    margin-right: 20px;
    margin-top: 70px;
}