@import '../splash.scss';

.main-feed {
    height: max-content;
    background-color: linear-gradient(#eccb0d, #fdaa2d);
    width: 100%;
    display: flex;
    padding-top: 50px;
}

.aside-left {
    width: 50%;
    background-color: transparent;
}

.aside-right {
    width: 40%;
    background-color: transparent;
}

.post-index {
    width: 550px;
    // background-color: rgb(253, 255, 228);
    background-color: white;
    padding: 10px 0 100px 0;
    margin: 0 auto;
    border-radius: 4px;
    min-height: 600px;
    box-sizing: border-box;

    ul{
        width: 100%;
    }
}

.post-index-item {
    width: 500px;
    padding: 30px 0 10px 0;
    height: max-content;
    box-sizing: border-box;
    border-bottom: 2px solid #cfffeb;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.pi-container {
    width: 600px;
    // padding: 0 20px 20px 20px;
    min-height: 600px;
    box-shadow: 1px 1px 3px rgb(150, 150, 150);
    // background-color: rgb(253, 255, 228);
    background-color: white;
    margin: 0 auto 50px auto;
}

.pi-container-profile {
    width: max-content;
    margin: 0 30px 0 30px;
    min-height: 600px;
    background-color: white;
}

.food-feed {
    width: inherit;
    height: auto;
    padding: 20px 20px 18px 20px;
    // border-bottom: 2px solid #cfffeb;
    box-sizing: border-box;
    background-color: #cfffeb;
    color: rgba(16, 196, 209, 0.904);
    font-size: 25px;
    font-weight: 700;
}

.food-feed-profile {
    width: 100%;
    height: auto;
    padding: 20px 20px 18px 20px;
    // border-bottom: 2px solid #cfffeb;
    box-sizing: border-box;
    background-color: #cfffeb;
    color: rgba(16, 196, 209, 0.904);
    font-size: 25px;
    font-weight: 700;
}