.message {
    display: flex;
    flex-direction: column;
}

.messageTop{
    display: flex;
    margin-top:20px;
    align-items: center;
}

.messageImg{
    width: 32px;
    height:32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right:10px;
}

.message-text{
    padding:10px;
    border-radius: 20px;
    background-color: rgb(45, 72, 221);
    color:white;
    max-width: 300px;
    word-wrap: break-word;
}

.messageBottom{
    font-size: 12px;
    margin-top: 7px;
}

.message.own{
    align-items: flex-end;
}

.message.own .message-text{
    background-color: rgb(231, 231, 231);
    color:black;
}