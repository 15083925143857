.messenger{
    height: calc(100vh - 20px);
    display: flex;
}

.chatMenu{
    flex:3;
}

.chatBox{
    flex:5.5;

}


.chatBoxBottom{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-message-input{
    width:80%;
    height: 90px;
    padding:10px;
}



.chatSubmitBtn{
    width:84%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: teal;
    color: white;
    margin-top: 5px;
}

.chatOnline{
    flex:3;
}

.chatMenuWrapper, .chatBoxWrapper, .chatOnlineWrapper{
    padding:10px;
    background-color: rgb(251, 255, 255);
    height:100%;
    
}

.chatMenuInput{
    width:90%;
    padding: 10px 0;
}

.chatBoxWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
}

.chatMenuWrapper, .chatOnlineWrapper{
    background-color: rgb(235, 232, 232);
}

.chatBoxTop{
    height:100%;
    overflow-y: scroll;
    padding-right: 10px;
}

.noConversationText{
    font-size: 2rem;
    color:rgb(10, 10, 10);
    cursor: default;
    text-align: center;
    margin-top: 20px;
}


.friendHandle{
    text-align: center;
    height:auto;
    padding:20px;
    border-bottom: 1px solid rgb(230, 228, 228);

}

.friendHandleh1{
    font-size: 30px;
    font-weight: bold;
    color:#fc6551;
}

 .spinner::before {
        animation: 1s linear infinite spinner;
        animation-play-state: inherit;
        border: solid 5px #cfd0d1;
        border-bottom-color: #1c87c9;
        border-radius: 50%;
        content: "";
        height: 40px;
        width: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        will-change: transform;
}

.spinner h4 {
    position: absolute;
    top:43%;
    left:47%;
    font-size: 18px;
}

@keyframes spinner {
        0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
      }